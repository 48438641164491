.learning_hub-offer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin: 5rem;
  padding: 3rem;
  border-radius: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.learning_hub-offer-main {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 3rem;
  padding: 0rem;
}
.learning_hub-offer-heading {
  color: var(--color-skyblue);
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
  margin-inline: auto;
}
.learning_hub-offer-card-content {
  margin-block: auto;
  padding: 0rem;
  text-align: center;
}
.learning_hub-offer-card2 {
  background-color: var(--color-lightblue);
  border-radius: 10px;
  padding: 1.5rem 4rem;
  margin-block: auto;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: var(--color-skyblue);
  cursor: pointer;
}
.learning_hub-offer-card2:hover {
  border: 2px solid var(--color-skyblue);
}
.learning_hub-offer-card1 {
  background-color: var(--color-lightred);
  border-radius: 10px;
  padding: 1.5rem 4rem;
  margin-block: auto;
  text-align: center;
  font-weight: 600;
  color: var(--color-red);
  cursor: pointer;
}
.learning_hub-offer-card1:hover {
  border: 2px solid var(--color-red);
}
.learning_hub-offer-card3 {
  background-color: var(--color-lightgreen2);
  border-radius: 10px;
  padding: 1.5rem 4rem;
  margin-block: auto;
  text-align: center;
  font-weight: 600;
  color: var(--color-skyblue);
  cursor: pointer;
}
.learning_hub-offer-card3:hover {
  border: 2px solid var(--color-skyblue);
}

@media screen and (max-width: 1100px) {
  .learning_hub-offer {
    margin: 2rem;
    padding: 2rem;
  }
  .learning_hub-offer-main {
    grid-template-columns: auto auto;
  }
  .learning_hub-offer-heading {
    font-size: 1.6rem;
  }
  .learning_hub-offer-card-content {
    white-space: nowrap;
  }
  .learning_hub-offer-card2 {
    padding: 1.5rem 2rem;
  }
  .learning_hub-offer-card1 {
    padding: 1.5rem 2rem;
  }
  .learning_hub-offer-card3 {
    padding: 1.5rem 2rem;
  }
}

@media screen and (max-width: 600px) {
  .learning_hub-offer-main {
    grid-template-columns: auto;
    gap: 1rem;
  }
}
