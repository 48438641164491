.learning_hub-customer {
  background-color: var(--color-lightgreen);
  border-radius: 1rem;
  padding: 6rem;
  text-align: center;
}
.learning_hub-heading {
  color: var(--color-skyblue);
  font-weight: 600;
  font-size: 3rem;
}
.learning_hub-heading-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learning_hub-heading-rating-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.learning_hub-heading-ratings {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.learning_hub-heading-ratings p {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-text-grey);
}
.learning_hub-see-all {
  color: var(--color-skyblue);
  text-decoration: underline;
  font-weight: 600;
}
.learning_hub-heading-see {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-lightgreen);
}
.learning_hub-customer_btn {
  padding: 1rem 4rem;
  border-radius: 3rem;
  background-color: var(--color-orange);
  color: var(--color-white);
  font-size: 1.2rem;
}
.learning_hub-customer_btn:hover {
  background-color: var(--color-brown);
}
@media screen and (max-width: 1100px) {
  .learning_hub-customer {
    padding: 1rem;
  }
  .learning_hub-heading {
    font-size: 1.5rem;
  }
  .learning_hub-customer_arrow {
    font-size: 2rem;
  }
  .learning_hub-heading-ratings {
    flex-direction: column;
    gap: 0rem;
  }
  .learning_hub-heading-ratings p {
    font-size: 1rem;
  }
  .learning_hub-customer_btn {
    padding: 0.8rem 2rem;
    border-radius: 3rem;
    background-color: var(--color-orange);
    color: var(--color-white);
    font-size: 1rem;
  }
  .learning_hub-heading-rating-group {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 600px) {
  .learning_hub-heading-ratings {
    gap: 0rem;
    padding: 0.2rem 0rem;
  }
}
