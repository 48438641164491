.learning_hub-learning-hub-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.learning_hub-learning-hub_content {
  display: flex;
}
.learning_hub-learning-hub_heading {
  color: var(--color-skyblue);
  font-size: 4rem;
  font-weight: 600;
  padding: 4rem 0rem;
}

.learning_hub-learning-hub_branches-heading {
  color: var(--color-skyblue);
  font-size: 2rem;
  font-weight: 600;
  line-height: 0.6;
}
.learning_hub-learning-hub_branch-heading-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.learning_hub-learning-hub_branch-view-on-map {
  color: var(--color-red);
  text-decoration: underline;
  font-size: 0.8rem;
}
.learning_hub-learning-hub_map {
  width: 65%;
}
.learning_hub-learning-hub_map-main {
  border: 2px solid var(--color-skyblue);
  border-radius: 1rem;
}
.learning_hub-learning-hub_branches {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 0rem 2rem;
}
.learning_hub-learning-hub_branch-heading {
  font-weight: 600;
}

.learning_hub-learning-hub_head-branch {
  gap: 0rem;
}
.learning_hub-learning-hub_branch-div {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  gap: 1rem;
}
.learning_hub-learning-hub_branch {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}
@media screen and (max-width: 1000px) {
  .learning_hub-learning-hub_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .learning_hub-learning-hub_heading {
    padding: 4rem 0 0 0;
  }
  .learning_hub-learning-hub_branch-heading-div {
    flex-direction: column;
  }

  .learning_hub-learning-hub_map {
    width: 100%;
    height: 35rem;
    padding: 5rem;
  }
  .learning_hub-learning-hub_branches {
    width: 100%;
    align-items: center;
  }

  .learning_hub-learning-hub_branch-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 4rem;
    text-align: center;
  }
  .learning_hub-learning-hub_head-branch {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    gap: 0.1rem;
    max-width: 30rem;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 550px) {
  .learning_hub-learning-hub_heading {
    font-size: 2rem;
    padding-top: 2rem;
  }

  .learning_hub-learning-hub_map {
    height: 25rem;
    padding: 1rem;
  }
  .learning_hub-learning-hub_branches {
    padding: 2rem 2rem;
  }
  .learning_hub-learning-hub_branch-div {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
