.learning_hub-hero_main {
  display: flex;
  justify-content: space-between;
}
.learning_hub-hero_bg {
  position: absolute;
  z-index: -2;
  width: 100%;
  min-height: 60rem;
}
.learning_hub-hero_contactbg {
  position: absolute;
  z-index: -2;
}
.learning_hub-hero_img-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
}
.learning_hub-hero_img_landing,
.learning_hub-hero_img_contact {
  max-width: 100%;
}
.learning_hub-hero_img_about {
  max-width: 80%;
  border-radius: 50%;
}
.learning_hub-hero_aboutOwner_mobileview {
  display: none;
}
.learning_hub-hero_contactimg-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
  padding-top: 4rem;
}
.learning_hub-hero_text-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 4rem 3rem;
  max-width: 50%;
}
.learning_hub-hero_contact-form {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin: 1rem 0rem;
  width: auto;
}
.learning_hub-hero_contact-form input,
textarea {
  font-size: 1rem;
  padding: 0.6rem 1rem;
  border-radius: 1.5rem;
  border: 1px solid var(--color-text-grey) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.114),
    0 6px 20px 0 rgba(0, 0, 0, 0.102);
}

.learning_hub-hero_heading1 {
  font-size: 4rem;
  font-weight: 600;
  color: var(--color-skyblue);
  line-height: 1;
  white-space: nowrap;
}
.learning_hub-hero_heading2 {
  color: var(--color-skyblue);
  font-size: 3rem;
  font-weight: 600;
  white-space: nowrap;
}
.learning_hub-hero_point {
  color: var(--color-skyblue);
  font-size: 1.5rem;
  font-weight: 600;
  /* white-space: nowrap; */
}
.learning_hub-hero_content1,
.learning_hub-hero_content2 {
  color: var(--color-text-grey);
  font-size: 1.2rem;
  text-align: justify;
  font-weight: 600;
  /* max-width: 45rem; */
  white-space: pre-line;
  line-height: 1.4;
}
.learning_hub_hero-button {
  padding: 1rem 5rem;
  margin: 2rem auto 2rem 0rem;
  border-radius: 3rem;
  background-color: var(--color-orange);
  color: var(--color-white);
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
}
.learning_hub_hero-button:hover {
  background-color: var(--color-brown);
}
.learning_hub_hero-contact-button {
  padding: 1rem 5rem;
  margin-inline: auto;
  border-radius: 3rem;
  background-color: var(--color-orange);
  color: var(--color-white);
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
}
.learning_hub_hero-contact-button:hover {
  background-color: var(--color-brown);
}
.learning_hub-hero_name {
  color: var(--color-text-grey);
  font-weight: 600;
  font-size: 1.2rem;
}
.learning_hub-position {
  color: var(--color-text-grey);
  font-size: 1.1rem;
}
/* -----------Tab Css----------------- */
@media screen and (max-width: 1000px) {
  .learning_hub-hero_main {
    flex-direction: column;
    text-align: center;
    padding: 0rem 0rem;
  }
  .learning_hub-hero_bg {
    height: 80rem;
    object-fit: cover;
    overflow: hidden;
  }
  .learning_hub-hero_contactbg {
    width: 100%;
    height: 48rem;
    object-fit: cover;
    overflow: hidden;
  }
  .learning_hub-hero_img-div {
    display: none;
  }

  .learning_hub-hero_aboutOwner_mobileview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0rem auto;
    gap: 0.5rem;
  }
  .learning_hub-hero_img_landing {
    max-width: 30%;
  }
  .learning_hub-hero_img_about,
  .learning_hub-hero_img_contact {
    max-width: 50%;
  }
  .learning_hub-hero_name_mobile {
    color: var(--color-text-grey);
    font-weight: 600;
    font-size: 1rem;
  }
  .learning_hub-position_mobile {
    color: var(--color-text-grey);
    font-size: 0.8rem;
  }
  .learning_hub-hero_contactimg-div {
    display: none;
  }
  .learning_hub-hero_text-section {
    padding: 2rem 1rem;
    max-width: 100%;
  }
  .learning_hub-hero_contact-form {
    gap: 1rem;
    margin: 1rem auto;
    max-width: 50%;
  }
  .learning_hub-hero_contact-form input,
  textarea {
    padding: 1rem;
    max-width: 100%;
    font-size: 1rem;
  }
  .learning_hub-hero_heading1 {
    font-size: 2rem;
  }
  .learning_hub-hero_heading2 {
    font-size: 1rem;
  }
  .learning_hub-hero_content1,
  .learning_hub-hero_content2 {
    font-size: 1rem;
    text-align: center;
    width: 100%;
    white-space: normal;
    line-height: 1.2;
  }
  .learning_hub_hero-button {
    padding: 1rem 1.6rem;
    margin: 0.5rem auto;
    font-size: 0.8rem;
    font-weight: 600;
  }
}

/* --------------Mobile View-------------- */
@media screen and (max-width: 600px) {
  .learning_hub-hero_bg {
    height: 65rem;
  }
  .learning_hub-hero_contactbg {
    height: 46rem;
  }

  .learning_hub-hero_img_landing {
    max-width: 50%;
  }
  .learning_hub-hero_img_about,
  .learning_hub-hero_img_contact {
    max-width: 80%;
  }
  .learning_hub-hero_img_about {
    border-radius: 50%;
  }
  .learning_hub-hero_contact-form {
    margin: 1rem auto;
    max-width: 90%;
  }
  .learning_hub-hero_content1,
  .learning_hub-hero_content2 {
    font-size: 0.8rem;
  }
  .learning_hub_hero-contact-button {
    padding: 0.8rem 3rem;
  }
  .learning_hub-hero_point {
    color: var(--color-skyblue);
    font-size: 1rem;
    font-weight: 600;
    /* white-space: nowrap; */
  }
}

/* --------------Ultra wide View-------------- */

@media screen and (min-width: 2000px) {
  .learning_hub-hero_bg {
    max-width: 100rem;
  }
}
