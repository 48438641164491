.learning_hub-why-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 0rem;
  position: relative;
  gap: 2rem;
}
.learning_hub-why-us-heading {
  color: var(--color-orange);
  font-weight: 600;
  font-size: 3rem;
  text-align: center;
  width: 50%;
}
.learning_hub-why-us_img-div {
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
  width: 100%;
  padding: 0rem 2rem;
}
.learning_hub-why-us_img {
  max-width: 22%;
}
.learning_hub-why-us_familyimg {
  padding: 4rem 0rem;
  width: 100%;
}
.learning_hub-why-us_gradientbg {
  position: absolute;
  z-index: -2;
  bottom: 0;
  width: 100%;
}
.learning_hub-why-us-mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
}
.learning_hub-why-us-mission-heading {
  color: var(--color-orange);
  font-size: 3rem;
  font-weight: 600;
}
.learning_hub-why-us-mission-content {
  width: 70%;
  color: var(--color-text-grey);
  font-weight: 600;
  font-size: 1.2rem;
}

@media screen and (max-width: 1100px) {
  .learning_hub-why-us {
    padding: 2rem 1rem;
  }
  .learning_hub-why-us-heading {
    font-size: 1.8rem;
    width: 90%;
  }
  .learning_hub-why-us_img-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    /* width: 100%; */
    place-items: center;
  }
  .learning_hub-why-us_img {
    max-width: 100%;
  }
  .learning_hub-why-us_gradientbg {
    height: 55rem;
    object-fit: cover;
    overflow: hidden;
  }
  .learning_hub-why-us-mission {
    gap: 0.5rem;
  }
  .learning_hub-why-us-mission-heading {
    font-size: 1.8rem;
  }
  .learning_hub-why-us-mission-content {
    width: 100%;
    font-size: 1rem;
    padding: 0rem 1rem;
  }
}

@media screen and (max-width: 600px) {
  .learning_hub-why-us_img-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
