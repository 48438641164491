.learning-hub_blog_hero_img {
  width: 100%;
}

.learning-hub_blog_content_main-div {
  padding: 3rem 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  /* max-width: 80%; */
}

.learning-hub_blog_content_main-heading_div {
  text-align: center;
}

.learning-hub_blog_content_main-heading {
  font-size: 4rem;
  font-weight: 600;
  color: var(--color-skyblue);
}

.learning-hub_blog_content_div {
  background-color: var(--color-pink);
  padding: 2rem 0rem;
  display: grid;
  gap: 3rem;
  border-radius: 1rem;
}

.learning-hub_blog_content {
  display: grid;
  gap: 1rem;
}

.learning-hub_blog_content_heading {
  font-size: 2rem;
  color: var(--color-orange);
  font-weight: 600;
  width: 80%;
  margin-inline: auto;
}

.learning-hub_blog_content_text {
  text-align: justify;
  width: 80%;
  margin-inline: auto;
  display: grid;
  gap: 1rem;
}

.learning-hub_blog_content_healines {
  font-weight: 600;
  font-size: 1.1rem;
}
.learning-hub_blog_content_headings{
    font-weight: 600;
  font-size: 1.2rem;
}

@media screen and (max-width: 550px) {
  .learning-hub_blog_content_main-div {
    padding: 1rem;
  }

  .learning-hub_blog_content_div {
    background-color: var(--color-pink);
    padding: 1rem 0rem;
  }

  .learning-hub_blog_content_main-heading {
    font-size: 2.2rem;
    margin-block: 1rem;
  }

  .learning-hub_blog_content_heading {
    font-size: 1.5rem;
  }
  .learning-hub_blog_content_text {
    text-align: center;
    width: 90%;
  }
}
