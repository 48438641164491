.learning_hub-operational-hours {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5rem 0rem;
  gap: 1.5rem;
  background-color: var(--color-white);
  border-radius: 0rem 0rem 4rem 4rem;
  box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0);
}

.learning_hub-operational-hours-heading {
  color: var(--color-skyblue);
  font-size: 2rem;
  font-weight: 600;
  max-width: 100%;
}
.learning_hub-operational-hours-phone,
.learning_hub-operational-hours-email {
  color: var(--color-skyblue);
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 100%;
}
.learning_hub-operational-hours-section1 {
  background-color: var(--color-lightblue);
  border-radius: 10px;
  padding: 1rem 5rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: var(--color-skyblue);
}
.learning_hub-operational-hours-section2 {
  background-color: var(--color-lightred);
  border-radius: 10px;
  padding: 1rem 5rem;
  text-align: center;
  font-weight: 600;
  color: var(--color-red);
}
.learning_hub-operational-hours-section3 {
  background-color: var(--color-lightgreen);
  border-radius: 10px;
  padding: 1rem 5rem;
  text-align: center;
  font-weight: 600;
  color: var(--color-skyblue);
}
.learning_hub-operational-hours-main {
  display: flex;
  gap: 2rem;
}
.learning_hub-operational-hours-section1-content {
  color: var(--color-skyblue);
}
.learning_hub-operational-hours-section3-content {
  color: var(--color-green);
}
.learning_hub-operational-hours_appointment {
  text-align: center;
  margin-top: 2rem;
  display: grid;
  gap: 0.5rem;
}
.learning_hub-operational-hours_whatsapp {
  max-width: 90%;
  margin: 0 auto;
}
@media screen and (max-width: 1100px) {
  .learning_hub-operational-hours {
    padding: 2rem 1rem;
    gap: 0rem;
  }

  .learning_hub-operational-hours-heading {
    font-size: 1.8rem;
    padding: 1rem 0rem;
  }
  .learning_hub-operational-hours-section1 {
    padding: 1rem 3rem;
    margin: 0rem;
  }
  .learning_hub-operational-hours-section2 {
    padding: 1rem 3rem;
    margin: 0rem;
  }
  .learning_hub-operational-hours-section3 {
    padding: 1rem 3rem;
    margin: 0rem;
  }
  .learning_hub-operational-hours-main {
    gap: 1rem;
    flex-direction: row;
    margin-block: 0;
  }
  .learning_hub-operational-hours-phone,
  .learning_hub-operational-hours-email {
    color: var(--color-skyblue);
    font-size: 1rem;
    font-weight: 600;
    max-width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .learning_hub-operational-hours-heading {
    color: var(--color-skyblue);
    font-size: 1.5rem;
    padding: 0.5rem 0rem;
  }
  .learning_hub-operational-hours-section1 {
    padding: 1rem 4rem;
  }
  .learning_hub-operational-hours-section2 {
    padding: 1rem 4rem;
  }
  .learning_hub-operational-hours-section3 {
    padding: 1rem 4rem;
  }
  .learning_hub-operational-hours-main {
    flex-direction: column;
  }
}
