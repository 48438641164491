.learning-hub__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3.5rem;
  background-color: var(--color-white);
  z-index: 5;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.learning-hub__navbar-links_li {
  color: var(--color-text-grey);
  padding: 0rem 1.5rem;
  font-weight: 600;
  font-size: 1.3rem;
}

.learning-hub__navbar-links_li:hover {
  color: var(--color-brown);
}

.learning-hub__navbar-links_contact-btn {
  color: var(--color-white);
  padding: 1rem 2rem;
  border-radius: 2rem;
  font-weight: 600;
  font-size: 1.3rem;
  background-color: var(--color-orange);
}

.learning-hub__navbar-links_contact-btn:hover {
  background-color: var(--color-brown);
}

.learning-hub__navbar-links {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.learning-hub__navbar-links_container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.active {
  border-bottom: 4px solid var(--color-orange);
}

.learning-hub__navbar-menu {
  display: none;
  position: relative;
}

/* blog Dropdown */

.learning-hub_blog_dropdown {
  position: relative;
  display: inline-block;
  margin-inline-start: auto;
}

.learning-hub_blog_dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--color-white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.8rem;
  border-radius: 0.5rem;
  padding: 0.5rem 0rem;

}

.learning-hub_blog_dropdown:hover .learning-hub_blog_dropdown-content {
  display: flex;
}

.learning-hub_blog_navbar-links_li {
  color: var(--color-text-grey);
  padding: 0rem 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
  text-align: start;
}

.learning-hub_blog_navbar-links_li:hover {
  color: var(--color-brown);
}

@media screen and (max-width: 950px) {
  .learning-hub__navbar {
    padding: 1rem;
    z-index: 5;
  }

  .learning-hub__navbar-links_logo_container {
    width: 100%;
  }

  .learning-hub__navbar-links_container {
    display: none;
  }

  .learning-hub__navbar-menu {
    display: flex;
    flex-direction: column;
  }

  .learning-hub__navbar-menu_container-links {
    position: absolute;
    right: -1rem;
    top: 4rem;
    margin: 0.5rem 0rem;
    padding: 0rem 1rem;
    border-radius: 0.4rem;
    z-index: 15;
    width: 100vw;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  li {
    text-align: right;
    color: var(--color-text);
    font-family: var(--font-family);
    padding: 0.5rem 0rem;
    font-weight: bold;
  }

  .learning-hub__navbar_name {
    font-size: 1.6rem;
  }

  .learning-hub__navbar-menu_li {
    display: flex;
    align-items: center;
  }

  /* blog Dropdown */
  .learning-hub_blog_dropdown {
    position: relative;
    display: inline-block;
    margin-inline: auto;
  }

  .learning-hub_blog_dropdown-content {
    display: none;
    position: absolute;
    right: -175%;
    background-color: var(--color-white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 10;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    border-radius: 0.5rem;
    padding: 0.5rem 0rem;

  }

  .learning-hub_blog_dropdown:focus .learning-hub_blog_dropdown-content {
    display: flex;
  }

  .learning-hub_blog_navbar-links_li {
    color: var(--color-text-grey);
    padding: 0rem 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    white-space: nowrap;
    text-align: center;
  }

  .learning-hub_blog_navbar-links_li:hover {
    color: var(--color-brown);
  }

}

@media screen and (max-width: 550px) {
  .learning-hub__navbar-menu {
    margin-left: 0.5rem;
  }
}