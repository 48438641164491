.learning_hub-footer_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0rem;
  position: relative;
}
.learning_hub-footer_title {
  font-size: 2rem;
  color: var(--color-skyblue);
  font-weight: 600;
}
.learning_hub-footer_text {
  font-weight: 600;
  color: var(--color-text-grey);
}
.learning_hub-footer_logo {
  position: absolute;
  padding: 3rem;
  left: 0;
  bottom: 0;
  cursor: pointer;
}
.learning_hub-footer_feedback-btn{
  background-color: var(--color-orange);
  color: var(--color-white);
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  letter-spacing: 0.05rem;
}
@media screen and (max-width: 1000px) {
  .learning_hub-footer_main {
    text-align: center;
  }
  .learning_hub-footer_title {
    font-size: 1.5rem;
  }
  .learning_hub-footer_logo {
    position: relative;
  }
}
