.learning_hub-happy-students-heading {
  color: var(--color-orange);
  font-size: 3rem;
  text-align: center;
  font-weight: 600;
  padding: 4rem 0rem;
  width: 100%;
}
.learning_hub-happy-students {
  position: relative;
  padding: 6rem 0rem;
}
.learning_hub-happy-students-family {
  text-align: center;
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 3rem;
}
.learning_hub-happy-students-family-inner {
  display: flex;
  margin-inline: auto;
  position: relative;
}
.learning_hub-happy-students-family-text {
  background-color: var(--color-white);
  padding: 2rem 5rem;
  border-radius: 4rem;
  color: var(--color-orange);
  font-weight: 600;
  font-size: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 4;
}
.learning_hub-happy-students-family-pinkFlower {
  position: absolute;
  right: -4.5rem;
}
.learning_hub-happy-students-family-yellowFlower {
  position: absolute;
  left: -5rem;
}
.learning_hub-happy-students-img-group {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
}

.learning_hub-happy-students-img1 {
  grid-column-start: 1;
  grid-column-end: 2;
  width: 100%;
  height: 100%;
}
.learning_hub-happy-students-img2 {
  width: 100%;
  height: 100%;
}

.learning_hub-happy-students-img3 {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  height: 100%;
}
.learning_hub-happy-students-img4 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 100%;
}
.learning_hub-happy-students-img5 {
  grid-column-start: 1;
  grid-column-end: 4;
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .learning_hub-happy-students-heading {
    font-size: 1.8rem;
    padding: 3rem 0rem;
  }
  .learning_hub-happy-students {
    padding: 0rem 0rem;
  }
  .learning_hub-happy-students-family {
    display: none;
  }
  .learning_hub-happy-students-img-group {
    grid-template-columns: auto;
  }

  .learning_hub-happy-students-img1 {
    grid-column-start: auto;
    grid-column-end: auto;
    height: fit-content;
  }
  .learning_hub-happy-students-img2 {
    height: 10rem;
    object-fit: cover;
    overflow: hidden;
  }

  .learning_hub-happy-students-img3 {
    grid-column-start: auto;
    grid-column-end: auto;
  }
  .learning_hub-happy-students-img4 {
    grid-column-start: auto;
    grid-column-end: auto;
    grid-row-start: auto;
    grid-row-end: auto;
    width: 100%;
  }
  .learning_hub-happy-students-img5 {
    grid-column-start: auto;
    grid-column-end: auto;
  }
}
