.learning_hub-join_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  z-index: -3;
  padding: 4rem 0rem;
}
.learning_hub-join-us-heading {
  color: var(--color-skyblue);
  text-align: center;
  line-height: 1;
}
.learning_hub-join-us-subheading {
  font-size: 1rem;
  color: var(--color-text-grey);
}
.learning_hub-join-us-heading {
  font-size: 2rem;
  font-weight: 600;
}
.learning_hub-join-us-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem;
  width: 50rem;
  background-color: var(--color-white);
  border-radius: 3rem;
}
.learning_hub-join-us_form-input {
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid var(--color-text-grey);
  border-radius: 10px;
  font-size: 1.2rem;
}
.learning_hub-join-us_file-input-div {
  padding: 1rem 0rem;
  border-radius: 1rem;
  border: 1px solid var(--color-text-grey);
  border-radius: 10px;
  display: flex;
  padding: 0;
  align-items: center;
  gap: 1rem;
}
.learning_hub-join-us_file-input-btn {
  background-color: var(--color-grey);
  padding: 1rem;
  border-right: 1px solid var(--color-text-grey);
  border-radius: 10px;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
#user_file {
  text-align: center;
}
.learning_hub_join-button {
  padding: 1rem 3rem;
  background-color: var(--color-orange);
  margin-inline: auto;
  border-radius: 1rem;
  border: none;
  color: var(--color-white);
  font-size: 1.2rem;
  cursor: pointer;
}
.learning_hub_join-button:hover {
  background-color: var(--color-brown);
}
@media screen and (max-width: 800px) {
  .learning_hub-join_main {
    gap: 1rem;
    padding: 4rem 1rem;
  }

  .learning_hub-join-us-subheading {
    font-size: 0.8rem;
  }
  .learning_hub-join-us-heading {
    font-size: 1.5rem;
  }
  .learning_hub-join-us-form {
    gap: 1.5rem;
    padding: 2rem;
    width: 100%;
    border-radius: 2rem;
  }
  .learning_hub-join-us_form-input {
    padding: 0.8rem;
    font-size: 1rem;
  }
  .learning_hub-join-us_file-input-div {
    padding: 0.8rem 0rem;
    display: flex;
    padding: 0;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.8rem;
  }
  .learning_hub-join-us_file-input-btn {
    padding: 0.5rem 0.2rem;
    border: 1px solid var(--color-text-grey);
    font-size: 0.8rem;
    text-align: center;
  }
  .learning_hub_join-button {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
  .learning_hub-join-us_filename {
    overflow: hidden;
  }
}
