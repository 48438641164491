.learning_hub-appointment-main {
  display: flex;
  padding: 4rem 3rem;
  position: relative;
}
.learning_hub-appointment-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  position: absolute;
  right: 5rem;
}
.learning_hub-phone-heading,
.learning_hub-email-heading {
  color: var(--color-skyblue);
  font-size: 2rem;
  font-weight: 600;
  white-space: nowrap;
}
@media screen and (max-width: 1200px) {
  .learning_hub-appointment-main {
    padding: 4rem 1rem 0rem 1rem;
    gap: 1rem;
    flex-direction: column;
  }
  .learning_hub-appointment-content {
    align-items: center;
    gap: 0.5rem;
    position: relative;
    right: 0;
  }
  .learning_hub-phone-heading,
  .learning_hub-email-heading {
    color: var(--color-skyblue);
    font-size: 1rem;
  }
}
