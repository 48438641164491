@import url("https://fonts.googleapis.com/css2?family=Kanit&family=Lato:wght@300;400;700;900&family=Pacifico&family=Permanent+Marker&family=Poppins:ital,wght@0,100;0,200;0,300;0,600;0,700;1,200;1,400;1,500;1,600&family=Uchen&display=swap");

:root {
  --font-family: "Poppins";
  --color-brown: #ba5b12;
  --color-text-grey: #676363;
  --color-light-grey: #A5A2A2;
  --color-white: #ffffff;
  --color-skyblue: #31879f;
  --color-red: #ff7979;
  --color-lightblue: #dceff4;
  --color-lightred: #f8d8d8;
  --color-lightgreen: #def2eb;
  --color-lightgreen2: rgb(205, 255, 208);
  --color-orange: #ea7c27;
  --color-darkgreen: #676636;
  --color-green: #20926a;
  --color-lightorange: #fbe5d4;
  --color-back: linear-gradient(180deg,
      rgba(234, 124, 39, 0.2) 10.47%,
      rgba(242, 191, 38, 0.2) 100%);
  --color-grey: #e9e9e9;
  --color-pink: #FBE5D4;
}