.learning-hub_feedback_blue-strip {
    background-color: var(--color-skyblue);
    padding: 1rem 0rem;
    width: 100%;
}

.learning-hub_feedback_header {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.learning-hub_feedback_logo {
    width: 20%;
}
.learning-hub_feedback_logo img{
    width: 100%;
}

.learning-hub_feedback_dots {
    width: 6%;
}

.learning-hub_feedback_form {
    margin-inline: auto;
    width: 70%;
    display: grid;
    gap: 2rem;
    margin-bottom: 5rem;
}

.learning-hub_feedback_form_title {
    color: var(--color-skyblue);
    font-size: 4rem;
    text-align: center;
    font-weight: 600;
}

.learning-hub_feedback_form_name-last-div {
    display: flex;
    gap: 3rem;
}

.learning-hub_feedback_form_item-div {
    display: grid;
    gap: 1rem;
}

.learning-hub_feedback_form_name-div {
    width: 50%;
}

.learning-hub_feedback_form_input {
    width: 100%;
    font-size: 1.9rem;
    border: 2px solid var(--color-light-grey);
    border-radius: 0.8rem;
    padding: 1rem 0.5rem;
}
.learning-hub_feedback_form_user-rating-input {
    width: 50%;
    font-size: 1.9rem;
    border: 2px solid var(--color-light-grey);
    border-radius: 0.8rem;
    padding: 1rem 0.5rem;
}

.learning-hub_feedback_form_ratings-input-div {
    display: flex;
    gap: 2rem;
}

.learning-hub_feedback_form_item-div textarea {
    border: 2px solid var(--color-light-grey) !important;
}

.learning-hub_feedback_form_input:focus {
    outline: none;
    box-shadow: none;
}

.learning-hub_feedback_form_label {
    white-space: nowrap;
    font-size: 1.9rem;
    font-weight: 600;
}


.learning-hub_feedback_form_ratings-div {
    font-size: 4.5rem;
    display: flex;
    gap: 2rem;
}

.learning-hub_feedback_form_ratings-div button {
    font-size: 2.5rem;
    border: none;
    cursor: pointer;
}

.learning-hub_feedback_form_btn {
    margin-inline: auto;
    padding: 1rem 5rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--color-white);
    background-color: var(--color-orange);
    border: none;
    border-radius: 0.5rem;
    margin-block: 4rem;
}

.learning-hub_feedback_footer {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
}

.learning-hub_feedback_blue-strip-footer {
    background-color: var(--color-skyblue);
    padding: 2rem 0rem;
    width: 100%;
}

.learning-hub_feedback_footer_text {
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 3rem;
    text-align: center;
}


@media screen and (max-width: 950px) {

    .learning-hub_feedback_blue-strip {
        background-color: var(--color-skyblue);
        padding: 0rem 0rem;
        width: 100%;
    }

    .learning-hub_feedback_header {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
    }

    .learning-hub_feedback_logo {
        width: 30%;
    }

    .learning-hub_feedback_dots {
        width: 9%;
    }

    .learning-hub_feedback_form {
        margin: auto;
        width: 100%;
        display: grid;
        gap: 2rem;
        padding: 2rem;
        margin-bottom: 2rem;
    }

    .learning-hub_feedback_form_title {
        color: var(--color-skyblue);
        font-size: 2rem;
        text-align: center;
        font-weight: 600;
    }

    .learning-hub_feedback_form_name-last-div {
        display: flex;
        gap: 2em;
    }

    .learning-hub_feedback_form_item-div {
        display: grid;
        gap: 0rem;
    }

    .learning-hub_feedback_form_name-div {
        width: 100%;
    }

    .learning-hub_feedback_form_input {
        width: 100%;
        font-size: 1rem;
        border: 2px solid var(--color-light-grey);
        border-radius: 0.8rem;
        padding: 1rem 0.5rem;
    }

    .learning-hub_feedback_form_item-div textarea {
        border: 2px solid var(--color-light-grey) !important;
    }

    .learning-hub_feedback_form_input:focus {
        outline: none;
        box-shadow: none;
    }

    .learning-hub_feedback_form_label {
        white-space: nowrap;
        font-weight: 600;
        font-size: 1.2rem;
    }

   
    .learning-hub_feedback_form_ratings-div {
        display: flex;
        font-size: 2.5rem;
        justify-content: center;
        gap: 2rem;
    }

    .learning-hub_feedback_form_user-rating-input {
        width: 20%;
        font-size: 2rem;
        border: 2px solid var(--color-light-grey);
        border-radius: 0.8rem;
        padding: 0.5rem;
        margin: 1rem;
    }
    
    .learning-hub_feedback_form_ratings-div button {
        font-size: 1.9rem;
        border: none;
        background: none;
    }
    
    .learning-hub_feedback_form_ratings-input-div {
        justify-content: flex-start;
    }


    .learning-hub_feedback_form_btn {
        margin-inline: auto;
        padding: 0.5rem 3rem;
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--color-white);
        background-color: var(--color-orange);
        border: none;
        border-radius: 0.5rem;
        margin-block: 2rem;
    }

    .learning-hub_feedback_footer {
        padding: 2rem;
        display: flex;
        justify-content: space-between;
    }

    .learning-hub_feedback_blue-strip-footer {
        background-color: var(--color-skyblue);
        padding: 2rem 0rem;
        width: 100%;
    }

    .learning-hub_feedback_footer_text {
        font-weight: 600;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        text-align: center;
    }
}


@media screen and (max-width: 550px) {

    .learning-hub_feedback_blue-strip {
        background-color: var(--color-skyblue);
        padding: 0rem 0rem;
        width: 100%;
    }

    .learning-hub_feedback_header {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
    }

    .learning-hub_feedback_logo {
        width: 30%;
    }

    .learning-hub_feedback_dots {
        width: 9%;
    }

    .learning-hub_feedback_form {
        margin: auto;
        width: 100%;
        display: grid;
        gap: 2rem;
        padding: 2rem;
        margin-bottom: 2rem;
    }

    .learning-hub_feedback_form_title {
        color: var(--color-skyblue);
        font-size: 2rem;
        text-align: center;
        font-weight: 600;
    }

    .learning-hub_feedback_form_name-last-div {
        display: flex;
        gap: 2em;

        flex-direction: column;
    }

    .learning-hub_feedback_form_item-div {
        display: grid;
        gap: 0rem;
    }

    .learning-hub_feedback_form_name-div {
        width: 100%;
    }

    .learning-hub_feedback_form_input {
        width: 100%;
        font-size: 1rem;
        border: 2px solid var(--color-light-grey);
        border-radius: 0.8rem;
        padding: 1rem 0.5rem;
    }

    .learning-hub_feedback_form_item-div textarea {
        border: 2px solid var(--color-light-grey) !important;
    }

    .learning-hub_feedback_form_input:focus {
        outline: none;
        box-shadow: none;
    }

    .learning-hub_feedback_form_label {
        white-space: nowrap;
        font-weight: 600;
        font-size: 1.2rem;
    }


    .learning-hub_feedback_form_ratings-div {
        display: flex;
        font-size: 2.5rem;
        justify-content: center;
        gap: 2rem;
    }

    .learning-hub_feedback_form_user-rating-input {
        width: 20%;
        font-size: 2rem;
        border: 2px solid var(--color-light-grey);
        border-radius: 0.8rem;
        padding: 0.5rem;
        margin: 1rem auto;
        text-align: center;
    }

    .learning-hub_feedback_form_ratings-div button {
        font-size: 1.9rem;
        border: none;
        background: none;
    }

    .learning-hub_feedback_form_ratings-input-div {
        flex-direction: column;
        align-items: center;
    }


    .learning-hub_feedback_form_btn {
        margin-inline: auto;
        padding: 0.5rem 3rem;
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--color-white);
        background-color: var(--color-orange);
        border: none;
        border-radius: 0.5rem;
        margin-block: 2rem;
    }

    .learning-hub_feedback_footer {
        padding: 2rem;
        display: flex;
        justify-content: space-between;
    }

    .learning-hub_feedback_blue-strip-footer {
        background-color: var(--color-skyblue);
        padding: 2rem 0rem;
        width: 100%;
    }

    .learning-hub_feedback_footer_text {
        font-weight: 600;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        text-align: center;
    }
}